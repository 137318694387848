
import {mapActions, mapState} from "vuex";
import ListValues from "@/components/Mixin/ListValues";
import moment from "moment";

  export default {
    data: () => ({
      selected: [],
      deleteBtnVisible: false,
      deleteFormsModal: false,
      togleRefreshList: null,
      onRowClickOver: null,
      onRowDblClickOver: null,
      showDropdownSettings: false,
      columnSortMode: false,
      columnSortEnabled: false,
      filtersSelectModel: null
    }),
    methods: {
      ...mapActions('settings', ["getListSettings", "setListSettings", "saveListFilter", "cleanListSettings"]),
      refreshList() {
        this.togleRefreshList = !this.togleRefreshList;
      },
      onSelected(selected) {
        this.selected = selected;
      },
      // save pagination from the dynamic table to the model only
      savePagination(pagination) {
        if (this.filtersSelectModel) {
          this.filtersSelectModel.pagination = {...pagination};
        }
      },
      // save filter
      saveColumnSettings() {
        const settings = {
          module: this.moduleToSaveSettings,
          filter: { ...this.filtersSelectModel },
        };
        if(this.filtersSelectModel && this.filtersSelectModel.default) {
          settings.filter.filters = {};
        }
        this.saveListFilter(settings).then(() => {
          //store.state.alertMessage = "update";
          this.getListSettings(this.moduleToSaveSettings);
        });
      },
      getAllFields(fields1 = {}, fields2 = {}) {
        if (this.exDynFieldsAll) {
          //console.log(this.exDynFieldsAll);
          return this.exDynFieldsAll;
        } else {
          //console.log(this.dynFields);
          return {...this.dynFields, ...fields1, ...fields2};
        }
      },
      getAllColumnsForSelect() {
        const cols = [];
        const fields = ListValues.getValidColumns(this.getAllFields());
        for (const column in fields) {
          cols.push({name : column, label: fields[column].label || column});
        }
        return cols.sort(((a, b) => { return a.order - b.order }));
      },
      getSelectedString() {
        return this.selected.length === 0
          ? ""
          : `${this.selected.length} record${
            this.selected.length > 1 ? "s" : ""
          } ${this.$t("table.misc.selectedOf")} ${this.data.length}`;
      },
      getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
        return `${firstRowIndex}-${endRowIndex} ${this.$t(
          "table.misc.of"
        )} ${totalRowsNumber}`;
      },
    },
     beforeMount() {
      this.getListSettings(this.moduleToSaveSettings);
    },
    watch: {
      selected() {
        this.deleteBtnVisible = this.selected.length > 0;
      },
      module() {
        this.getListSettings(this.module);
        this.showTableRefresh += 1;
      },
      getDefaultFilter() {
        if (this.selectedFilter && this.selectedFilter.ID) {
          this.filtersSelectModel = this.selectedFilter;
        } else {
          if (this.getDefaultFilter && (this.getDefaultFilter.ID || this.getDefaultFilter.pagination)) {
            this.filtersSelectModel = this.getDefaultFilter;
          } else {
            this.filtersSelectModel = null;
          }
        }
      },
      filtersSelectModel() {
        //console.log(this.filtersSelectModel);
      },
    },
    computed: {
      ...mapState(["dynamicModules"]),
      ...mapState("settings", ["listSettings", "userFormsSettings"]),
      columns() {
        const sortable = !this.columnSortEnabled;
        const cols = [];
        const fields = this.getAllFields();
        const locVisibleColumns = this.visibleColumns;

        //console.log(fields);

        if (locVisibleColumns) {
          locVisibleColumns.filter(c => fields[c]).map((column) => {
            const label =  fields[column].label ? fields[column].label : column;
            const dateType =  fields[column].type === 'date' || fields[column].type === 'datetime';
            const newCol = {
              name: column,
              align: this.align,
              label: label,
              field: column,
            };
            if (sortable) {
              newCol.sortable = sortable;
              if (dateType) {
                newCol.sort = (a, b) => {
                  //console.log(a, moment(a, 'DD-MM-yyyy HH:mm'));
                  const d1 = moment(a, 'DD-MM-yyyy HH:mm');
                  const d2 = moment(b, 'DD-MM-yyyy HH:mm');
                  if (d1.isAfter(d2)) {
                    return 1;
                  } else if (d1.isBefore(d2)) {
                    return -1;
                  }
                  return 0;
                };
              }
            }
            if ((this.dropDownValues && this.dropDownValues[column]) ||
              (fields[column] && fields[column].type === 'checkbox')) {
              newCol.filterType = 'select';
            }
            cols.push(newCol);
          });
        }
        //console.log(cols);
        return cols;
      },
      // get visible columns for DynamicList
      visibleColumns: {
        get() {
          const  vColumns = this.filtersSelectModel ? this.filtersSelectModel.columns : [];
          const hiddenFields = []
          for (const f in this.dynFields){
            if (this.dynFields[f].hidden) hiddenFields.push(this.dynFields[f].name);
          }
          return vColumns.filter(e => !hiddenFields.includes(e));
        },
        set(val) {
          return this.filtersSelectModel.columns = [ ...val ];
        }
      },
      // get visible columns for BaseSectionRelatedRecords, sub records and product lines
      visibleFormListColumns: {
        get() {
          const  vColumns = this.userFormsSettings[this.moduleNameForFormSetting] && this.userFormsSettings[this.moduleNameForFormSetting].columns
            ? this.userFormsSettings[this.moduleNameForFormSetting].columns
            : [];
          const hiddenFields = []
          for (const f in this.dynFields){
            if (this.dynFields[f].hidden) hiddenFields.push(this.dynFields[f].name);
          }
          //console.log(vColumns.filter(e => !hiddenFields.includes(e)));
          return vColumns.filter(e => !hiddenFields.includes(e));
        }
      },
      savedPagination() {
        return this.filtersSelectModel ? this.filtersSelectModel.pagination : null;
      },
      filteredDataSaved() {
        return this.filtersSelectModel ? this.filtersSelectModel.filters : {};
      },
      getFilterData() {
        return this.listSettings ? this.listSettings[this.moduleToSaveSettings] : {};
      },
      getDefaultFilter() {
        return this.getFilterData && this.getFilterData.defaultFilter ? this.getFilterData.defaultFilter : {};
      },
      getFilters() {
        return this.getFilterData && this.getFilterData.filters ? this.getFilterData.filters : [];
      },
      selectedFilter() {
        return this.getFilterData ? this.getFilterData.selectedFilter : null;
      },
      module() {
        return this.dynModuleName;
      },
      dynModuleData() {
        return this.dynamicModules && this.dynamicModules[this.dynModuleName] ? this.dynamicModules[this.dynModuleName] : {} ;
      },
      dynTitle() {
        return this.dynModuleData ? this.dynModuleData.name : null;
      },
      dynFields() {
        return this.dynModuleData && this.dynModuleData.fields
          ? { ...ListValues.locFields(), ...this.dynModuleData.fields}
          : {};
      },
      allColumnsForSelect() {
        return this.getAllColumnsForSelect();
      },
      align() {
        return "left";
      },
      moduleToSaveSettings() {
        return this.moduleSettings || this.module;
      },
      dropDownValues() {
        return this.dynModuleData ? this.dynModuleData.dropDownValues : {};
      }
    },
  };
