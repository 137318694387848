//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from "vuex";
import store from "./../../store";
import tableDragger from 'table-dragger';
import ListValues from "@/components/Mixin/ListValues";
import {uid} from "quasar";
import {exportFile} from 'quasar';
import dynamic from "../../store/dynamic";
import {DayPilot} from "daypilot-pro-vue";

export default {
  name: "DynamicTable",
  props: {
    title: null,
    module: null,
    filters: null,
    searchFilter: null,
    moduleSettings: null,
    openAsRelated: null,
    columns: null,
    visibleDefaultColumns: null,
    defaultColumns: null,
    togleRefreshList: null,
    onRowClickOver: null,
    onRowDblClickOver: null,
    columnSortEnabled: null,
    dynModuleName: null,
    dynFields: null,
    selectedString: null,
    paginationLabel: null,
    defaultPagination: null,
    filteredDataSaved: null,
    exData: null,
    isPlanBoardMode: null
  },
  data: () => ({
    loading: false,
    separator: "horizontal",
    serverPagination: {
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 10,
      sortBy: "changedAt",
      descending: true,
    },
    localPagination: {
      sortBy: "changedAt",
      descending: true,
    },
    selected: [],
    visibleColumns: [],
    deleteBtnVisible: false,
    draggerHeaders: null,

    // QGrid
    columnOptionsSelected: {},
    filterData: {},
    uuid: '',
    columnOptions: {},
    filterFlags: {},

  }),
  methods: {
    onRowClick(evt, row) {
      if (this.onRowDblClickOver) {
        this.onRowClickOver(evt, row);
      } else {
        const smodal = this.isPlanBoardMode ? '/modal' : '';
        this.$router.push({path: this.getEditPath() + smodal + '/edit/' + row.id});
      }
    },
    onRowDblClick(evt, row) {
      if (this.onRowDblClickOver) {
        this.onRowDblClickOver(evt, row);
      }
    },
    getEditPath() {
      if (this.isPlanBoardMode) {
        return '/modules/appointments'
      } else {
        return this.$route.path;
      }
    },
    async onRequest({ pagination, filter }) {

      if (store.state.sourceForRecordLists === 'firestore-query') {
        const {sortBy, descending, rowsPerPage} = pagination;
        let {page} = pagination;
        let arrow = page - this.serverPagination.page;

        if (
          rowsPerPage != this.serverPagination.rowsPerPage ||
          sortBy != this.serverPagination.sortBy ||
          descending != this.serverPagination.descending
        ) {
          arrow = 0;
          page = 1;
          this.savePaginationSettings({rowsPerPage, sortBy, descending});
        }

        this.loading = true;
        await this.getRecords({
          module: this.module,
          sortBy: sortBy,
          descending: descending,
          filter: filter,
          page: page,
          rowsPerPage: rowsPerPage,
          arrow: arrow,
          filters: this.filters,
        });

        const curCount = this.data.length;
        this.serverPagination.rowsNumber =
          curCount < rowsPerPage
            ? rowsPerPage * (page - 1) + curCount
            : rowsPerPage * (page + 1);
        this.serverPagination.page = page;
        this.serverPagination.rowsPerPage = rowsPerPage;
        this.serverPagination.sortBy = sortBy;
        this.serverPagination.descending = descending;
        this.loading = false;
      }
    },
    refreshList() {
      this.serverPagination.page = 1;
      this.localPagination.page = 1;
      this.onRequest({
        pagination: this.serverPagination,
        filter: this.filter,
      });
      this.runMakeDraggable();
    },
    savePaginationSettings(paginationToSave) {
      const pagination = paginationToSave ?? this.serverPagination;
        this.$emit('savePagination', {
          rowsPerPage : pagination.rowsPerPage,
          sortBy : pagination.sortBy,
          descending : pagination.descending
        });
    },
    doTableHeadersDragable(enabled) {
      if (this.draggerHeaders !== null) {
        this.draggerHeaders.destroy();
      }
      if (enabled) {
        const div = document.getElementsByClassName('my-sticky-header-column-table')[0];
        const el = div.getElementsByClassName('q-table')[0];

        this.draggerHeaders = tableDragger(el, {
          mode: 'column',
          dragHandler: 'th',
          onlyBody: true,
        });

        this.draggerHeaders.on('drop', (from, to) => {
          this.$emit("onColumnDrop", {from, to});
        });
      }
    },
    exportTable(type) {
      // naive encoding to csv format
      const content = [this.columns.map(col => this.wrapCsvValue(col.label))].concat(
        this.data.map(row => this.columns.map(col => this.wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n');

      const status = exportFile(
        this.file_name+'.' + type,
        content,
        'text/' + type
      );

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0
        ? formatFn(val)
        : val;
      formatted = formatted === void 0 || formatted === null
        ? ''
        : String(formatted)
      formatted = formatted.split('"').join('""');
      /**
       * Excel accepts \n and \r in strings, but some other CSV parsers do not
       * Uncomment the next two lines to escape new lines
       */
      // .split('\n').join('\\n')
      // .split('\r').join('\\r')
      return `"${formatted}"`
    },
    // QGrtid
    setColumnFilterOptions(cols) {
      const off = true;
      if (off) {
        const columnOptions = {};
        cols.filter((item) => {
          columnOptions[item.field] = [];
          this.$set(this.columnOptionsSelected, item.field, []);
          this.filterFlags[item.field] = false;
          return item
        });

        this.data.filter((item) => {
          cols.filter(function (column) {
            if (item[column.field] && column.filterType === 'select') {
              columnOptions[column.field].push({
                label: item[column.field].toString(),
                value: item[column.field].toString().toLowerCase().replace(/_/g, '_')
              })
            }
          });
        });

        cols.filter((column) => {
          if (column.filterType === 'select') {
            columnOptions[column.field].push({ label: '-', 'value': '' });
            columnOptions[column.field] = [...new Map(columnOptions[column.field].map(item =>
              [item['value'], item])).values()];
          }
        });
        this.columnOptions = columnOptions;
        return columnOptions;
      } else {
        this.columnOptions = [];
        return [];
      }
    },
    getSelectedString() {
      return this.selected.length === 0
        ? ""
        : `${this.selected.length} record${
          this.selected.length > 1 ? "s" : ""
        } ${this.$t("table.misc.selectedOf")} ${this.data.length}`;
    },
    getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} ${this.$t(
        "table.misc.of"
      )} ${totalRowsNumber}`;
    },
    // Draggable
    async makeDraggable() {
      console.log('makeDraggable');
      const elements = document.getElementsByClassName("j-order-id");
      for (let i = 0; i < elements.length; i++) {
        DayPilot.Scheduler.makeDraggable({
          element: elements[i].parentElement.parentElement,
          orderID: elements[i].getAttribute("data-id"),
          appoID: elements[i].getAttribute("appo-id"),
          keepElement: true,
        });
      }
    },
    async runMakeDraggable() {
      // TODO refactoring it (check when q-table finish rendering)
      if(!this.isPlanBoardMode) {
        return false;
      }
      setTimeout(this.makeDraggable, 500);
      setTimeout(this.makeDraggable, 1000 * 1);
      setInterval(this.makeDraggable, 2000);
      /*
      setTimeout(this.makeDraggable, 500);
      setTimeout(this.makeDraggable, 1000 * 1);
      setTimeout(this.makeDraggable, 1000 * 2);
      setTimeout(this.makeDraggable, 1000 * 4);
      setTimeout(this.makeDraggable, 1000 * 6);
      setTimeout(this.makeDraggable, 1000 * 8);
      setTimeout(this.makeDraggable, 1000 * 10);
       */
    },
  },
  beforeMount() {
    this.refreshList();
  },
  watch: {
    columns() {
      this.setColumnFilterOptions(this.columns);
    },
    selected() {
      this.$emit("onSelected", this.selected);
    },
    // get visible columns for DynamicTable
    visibleDefaultColumns() {
      if (this.visibleDefaultColumns) {
        this.visibleColumns = this.visibleDefaultColumns;
      }
    },
    togleRefreshList() {
      this.refreshList();
    },
    columnSortEnabled() {
      this.doTableHeadersDragable(this.columnSortEnabled);
    },
    localPagination() {
      if (this.isLocalPng) {
        if (
          this.localPagination.oldRowsPerPage !== this.localPagination.rowsPerPage ||
          this.localPagination.oldSortBy !== this.localPagination.sortBy ||
          this.localPagination.oldDescending !== this.localPagination.descending
        ) {
          this.savePaginationSettings(this.localPagination);

          this.localPagination.oldRowsPerPage = this.localPagination.rowsPerPage;
          this.localPagination.oldSortBy = this.localPagination.sortBy;
          this.localPagination.oldDescending = this.localPagination.descending;
        }
      }
    },
    defaultPagination() {
      if(this.defaultPagination) {
        this.serverPagination = { ...this.defaultPagination };
        this.localPagination = { ...this.defaultPagination };

        this.localPagination.oldRowsPerPage = this.defaultPagination.rowsPerPage;
        this.localPagination.oldSortBy = this.defaultPagination.sortBy;
        this.localPagination.oldDescending = this.defaultPagination.descending;
      }
    },
    filteredDataSaved() {
      if (this.filteredDataSaved) {
        this.columnOptionsSelected = this.filteredDataSaved.columnOptionsSelected ?? {};
        this.filterData = this.filteredDataSaved.filterData ?? {};
      }
    },
    filterData() {
      this.$emit('filteredDataSave', { filterData : this.filterData});
    },
    columnOptionsSelected() {
      this.$emit('filteredDataSave', {columnOptionsSelected: this.columnOptionsSelected});
    },
  },
  computed: {
    ...mapGetters(["dynamicModulesData"]),
    ...mapState("settings", ["listSettings"]),
    tabColumns() {
      if (this.isPlanBoardMode) {
        return [{
          name: 'ID',
          align: this.align,
          label: 'ID',
          field: 'ID',
        }].concat(this.columns);
      } else {
        return this.columns;
      }
    },
    tabVisibleColumns() {
      if (this.isPlanBoardMode) {
        return ['ID'].concat(this.visibleColumns);
      } else {
        return this.visibleColumns;
      }
    },
    data() {
      if (this.isPlanBoardMode) {
        return this.exData;
      }
      if (this.module) {
        return ListValues.transform(dynamic.state[this.module] || [], this.dynFields);
      } else {
        console.log('No Model for '+ this.module);
        return [];
      }
    },
    getFilteredData() {
      const tableColumns = this.visibleColumns.map((item) => {
        return item
      });
      const tableData = this.data.filter((item) => {
        let i = '';
        for (i = 0; i < tableColumns.length; i++) {
          if (this.filterData[tableColumns[i]] == '')
            continue;

          if (item[tableColumns[i]] !== undefined) {
            const value = (item[tableColumns[i]] && item[tableColumns[i]].name) ? item[tableColumns[i]].name : item[tableColumns[i]];
            const colName = tableColumns[i];
            const inFilter = colName in this.filterData;
            if (inFilter && (!value || value.toString().toLowerCase().indexOf(this.filterData[colName].toLowerCase()) == -1)) {
              return false;
            }
          }
        }
        return true
      });
      return tableData
    },
    getFilteredValuesData() {
      const tableData = this.getFilteredData.filter((item) => {
        let i = '';
        for (i = 0; i < this.visibleColumns.length; i++) {
          if (this.visibleColumns[i] && this.columnOptionsSelected[this.visibleColumns[i]] && this.columnOptionsSelected[this.visibleColumns[i]].length == 0)
            continue;

          const itemValue = item[this.visibleColumns[i]] ? item[this.visibleColumns[i]].toString().toLowerCase() : '';
          if (this.visibleColumns[i] && this.columnOptionsSelected[this.visibleColumns[i]] && this.columnOptionsSelected[this.visibleColumns[i]].indexOf(itemValue) == -1) {
            return false;
          }
        }
        return true
      });
      return tableData;
    },
    align() {
      return "left";
    },
    moduleToSaveSettings() {
      return this.moduleSettings || this.module;
    },
    isShowMultiSelection() {
      return this.openAsRelated ? 'none' : 'multiple';
    },
    isShowColumnFilter() {
      return !this.openAsRelated;
    },
    isShowHeaderFilter() {
      return false;
    },
    isLocalPng() {
      return store.state.sourceForRecordLists === 'firestore-subscribe'
    },
    showTable() {
      return this.defaultPagination && this.columns;
    }
  },
  // QGrid
  created() {
    this.uuid = uid();
    if (this.file_name === undefined) {
      this.name = 'Download';
    } else {
      this.name = this.file_name;
    }
  },
};
