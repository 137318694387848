//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ListMixin from "../Mixin/ListMixin";
import { mapActions } from "vuex";
import DynamicTable from "../Forms/DynamicTable";
import AbModel from '@/components/Models/AbModel'

export default {
  name: "DynamicList",
  mixins: [ListMixin],
  components: { DynamicTable },
  props: {
    dynModuleName: null,
    isPlanBoardMode: null,
    exData: null,
    exDynFieldsAll: null
  },
  data: () => ({
    title: '',
    filterByName: "",
    showTableRefresh: 0,
    filtersSelectModel: null,
    showAddFilter: false,
    filterSave: {name: '', shared: false, default: false, columns: [], filters: {}, pagination: {rowsPerPage : 10}},
    filterEditName: '',
    filterEditSelect: {},
    filtersTab: "addFilter",
  }),
  methods: {
    ...mapActions('settings', ["saveListFilter", "deleteListFilter"]),
    addForm() {
      this.$router.push({
        name: this.isPlanBoardMode ? 'dynamicModules.formModal' : "dynamicModules.form",
        params: {moduleName: this.dynModuleName, action: "add"},
      });
    },
    deleteForms() {
      this.selected.forEach((e) => {
        new AbModel().delete(e.ID, this.module)
        .then(() => {
          this.refreshList();
        });
      });
      this.selected = [];
    },
    showAddFilterForm() {
      this.showAddFilter = true;
      this.filterEditName = '';
      this.filterEditSelect = null;
      this.filtersTab = "addFilter";
    },
    // save form Dynamic table to model only
    saveFiltersData(filterData) {
      if (this.filtersSelectModel) {
        if (filterData.columnOptionsSelected) {
          this.filtersSelectModel.filters.columnOptionsSelected = filterData.columnOptionsSelected;
        }
        if (filterData.filterData) {
          this.filtersSelectModel.filters.filterData = filterData.filterData;
        }
      }
    },
    // create new filter by (+)
    saveFilterSettings() {
      const settings = {
        module: this.moduleToSaveSettings,
        filter: { ...this.filterSave },
      };
      settings.filter.columns = this.visibleColumns;
      settings.filter.filters = this.filtersSelectModel.filters ?? [];
      this.saveListFilter(settings).then((res) => {
        this.filterSave.name = '';
        this.applyFilters({ ID: res.id});
      });
    },
    // save edited filter name from (+)
    editFilterSettings() {
      if (this.filterEditSelect && this.filterEditSelect.ID) {
        const settings = {
          module: this.moduleToSaveSettings,
          filter: { ID: this.filterEditSelect.ID, name: this.filterEditName },
        };
        this.saveListFilter(settings).then(() => {
          this.filterEditName = '';
          this.getListSettings(this.moduleToSaveSettings);
        });
      }
    },
    // delete the filter from (+)
    deleteFilterSettings() {
      if (this.filterEditSelect && this.filterEditSelect.ID) {
        const settings = {
          module: this.moduleToSaveSettings,
          filter: { ...this.filterEditSelect },
        };
        this.deleteListFilter(settings).then(() => {
          this.getListSettings(this.moduleToSaveSettings);
        });
        this.filterEditName = '';
        this.filterEditSelect = null;
      }
    },
    // select the filter
    applyFilters(filter) {
      this.setListSettings({module: this.moduleToSaveSettings, data: { selectedFilterID : filter.ID }}).then(() => {
        this.getListSettings(this.moduleToSaveSettings);
      });
    },
    selectFilterToEdit() {
      this.filterEditName = this.filterEditSelect.name;
    },
    onColumnDrop(pos) {
      if (this.isPlanBoardMode) {
        // -2 if we add first field ID
        pos.from -= 2; pos.to -= 2;
      }  else {
        pos.from -= 1; pos.to -= 1;
      }
      const val = this.filtersSelectModel.columns[pos.from];
      this.filtersSelectModel.columns.splice(pos.from, 1);
      this.filtersSelectModel.columns.splice(pos.to, 0, val);
      this.saveColumnSettings();
      this.refreshList();
    },
  },

  computed: {
    divClass() {
      return this.isPlanBoardMode ? '' : 'q-pa-md';
    },
    moduleSettings() {
      return this.isPlanBoardMode ? 'planboard' : null;
    },
    filtersForQTable() {
      return [];
    },
    filtersOptions() {
      return this.getFilters;
    },
    filtersOptionsEdit() {
      return this.getFilters.filter(e => !e.default);
    },
  },
  beforeRouteUpdate (to, from, next) {
    this.showTableRefresh += 1;
    next();
  }
};
